import React, { useContext } from 'react';
import { Flex, useDisclosure, useUpdateEffect } from '@chakra-ui/react';
import Brand from '../../../ui/Brand';
import Context from '../context';
import MobileNavButton from './MobileNavButton';
import MobileNavContent from './MobileNavContent';
import Nav from './Nav';

export const Content = () => {
  const { navRoutes, transparent, stuck } = useContext(Context);
  const mobileNav = useDisclosure();
  const mobileNavBtnRef = React.useRef();

  useUpdateEffect(() => {
    mobileNavBtnRef.current?.focus();
  }, [mobileNav.isOpen]);

  return (
    <>
      <Flex w="100%" h="100%" px="6" align="center" justify="space-between">
        <Brand {...{ transparent, stuck }} />

        <Flex justify="flex-end" w="100%" maxW="824px" align="center">
          <Nav navRoutes={navRoutes} />
          {/*<ColorModeSwitcher color="gray.400" />*/}
          <MobileNavButton ref={mobileNavBtnRef} onClick={mobileNav.onOpen} />
        </Flex>
      </Flex>
      <MobileNavContent
        isOpen={mobileNav.isOpen}
        onClose={mobileNav.onClose}
        navRoutes={navRoutes}
      />
    </>
  );
};

export default Content;
