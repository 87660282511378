import { mode } from '@chakra-ui/theme-tools';

const styles = {
  global: props => ({
    body: {
      color: 'gray.800',
      bg: mode('brand.50', 'brand.900')(props),
    },
  }),
};

export default styles;
