import React, { useEffect, useRef, useState } from 'react';
import { chakra, StylesProvider, useMultiStyleConfig } from '@chakra-ui/react';
import { useViewportScroll } from 'framer-motion';
import Content from './components/Content';
import Context from './context';

export const Header = ({ brand, navRoutes, transparent, ...rest }) => {
  const ref = useRef();
  const { height = 0 } = ref.current?.getBoundingClientRect() ?? {};
  const { scrollY } = useViewportScroll();
  const [y, setY] = useState(scrollY.current);
  const stuck = y > height;

  useEffect(() => scrollY.onChange(() => setY(scrollY.get())), [scrollY]);

  const styleProps = {
    variant: !stuck && transparent ? 'transparent' : undefined,
    size: stuck ? 'sm' : 'md',
  };
  const styles = useMultiStyleConfig('Header', styleProps);

  return (
    <Context.Provider value={{ brand, navRoutes, transparent, stuck }}>
      <chakra.header
        ref={ref}
        shadow={y > height ? 'sm' : undefined}
        sx={styles.container}
        pos="fixed"
        top="0"
        zIndex="1"
        left="0"
        right="0"
        width="full"
        {...rest}
      >
        <chakra.div sx={styles.contentWrap} mx="auto" maxW="1200px">
          <StylesProvider value={styles}>
            <Content />
          </StylesProvider>
        </chakra.div>
      </chakra.header>
    </Context.Provider>
  );
};

export default Header;
