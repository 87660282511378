import React from 'react';
import {
  Box,
  Flex,
  Container,
  Heading,
  Text,
  Center,
  VStack,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaCogs, FaRocket } from 'react-icons/fa';
import { MdImportantDevices, MdCode } from 'react-icons/md';

const What = () => {
  const c = {
    base: useColorModeValue('gray.50', 'gray.100'),
    accent: useColorModeValue('gray.100', 'gray.50'),
    bg: useColorModeValue('palette.b4', 'palette.b4'),
    icon: useColorModeValue('palette.r1', 'palette.r1'),
  };

  return (
    <Box
      as={'section'}
      w={'100%'}
      bg={c.bg}
      overflow="hidden"
      px={4}
      boxSizing="border-box"
    >
      <Container maxW="1200px" py={{ base: 8, lg: '60px' }} px={0}>
        <Heading
          as={'h2'}
          size={'2xl'}
          lineHeight={'1.5'}
          color={c.base}
          fontWeight={'thin'}
          textAlign={'center'}
          mb={10}
        >
          Custom Web Application Development Services
        </Heading>
        <Center>
          <VStack spacing={6} w={'80%'}>
            <Flex
              align={['stretch', 'flex-start']}
              flexDir={['column', 'row']}
              justify={'center'}
            >
              <Icon
                as={FaCogs}
                w={10}
                h={10}
                ml={['auto', 3]}
                mr={['auto', 8]}
                my={3}
                color={c.icon}
              />
              <Text color={c.accent}>
                <b>Custom software</b> helps you to achieve specific business
                goals. I can offer you tailor-made, scalable and secure web
                applications, such as company-facing or customer-facing web
                portals, process simplification applications and many other
                tailored solutions, that will encourage your business growth -
                serve more customers, reduce operating costs, automate
                processes, and maximize efficiency.
              </Text>
            </Flex>
            <Flex
              align={'center'}
              justify={'center'}
              display={['none', 'inline-block']}
            >
              <Icon as={MdCode} color={c.icon} />
            </Flex>
            <Flex
              align={['stretch', 'flex-start']}
              flexDir={['column', 'row']}
              justify={'center'}
            >
              <Icon
                as={MdImportantDevices}
                w={10}
                h={10}
                ml={['auto', 3]}
                mr={['auto', 8]}
                my={3}
                color={c.icon}
              />
              <Text color={c.accent}>
                <b>Progressive Web Apps (PWA) Development</b> - PWAs can be an
                excellent alternative to native mobile apps because businesses
                can enjoy mobile experience built as a web solution which works
                across all devices (single application for desktop, mobile or
                tablet) with top-notch UX and without the need for significant
                investment.
              </Text>
            </Flex>
            <Flex
              align={'center'}
              justify={'center'}
              display={['none', 'inline-block']}
            >
              <Icon as={MdCode} color={c.icon} />
            </Flex>
            <Flex
              align={['stretch', 'flex-start']}
              flexDir={['column', 'row']}
              justify={'center'}
            >
              <Icon
                as={FaRocket}
                w={10}
                h={10}
                ml={['auto', 3]}
                mr={['auto', 8]}
                my={3}
                color={c.icon}
              />
              <Text color={c.accent}>
                <b>MVP Development</b> - Minimum Viable Product is a great
                method to quickly gather feedback regarding your idea. It is all
                about maximizing the ROI while minimizing the technical effort.
                MVP allows you to avoid the pitfalls of implementing costly
                features that might be unnecessary and find ones that might have
                been missed. This is achieved by first developing a version of
                the product with just enough features to be usable by early
                users who can then provide feedback for future product
                development.
              </Text>
            </Flex>
          </VStack>
        </Center>
      </Container>
    </Box>
  );
};

export default What;
