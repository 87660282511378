import React, { forwardRef } from 'react';
import { IconButton } from '@chakra-ui/react';
import { AiOutlineMenu } from 'react-icons/ai';

export const MobileNavButton = forwardRef((props, ref) => (
  <IconButton
    ref={ref}
    display={{ base: 'flex', md: 'none' }}
    aria-label="Open Menu"
    fontSize="20px"
    colorScheme="brand"
    variant="ghost"
    icon={<AiOutlineMenu />}
    {...props}
  />
));

export default MobileNavButton;
