import { useColorMode } from '@chakra-ui/react';
import React from 'react';

export const Brand = ({
  transparent,
  stuck,
  height = '50%',
  forceUnicolor = null,
}) => {
  const { colorMode } = useColorMode();

  let fill = ['#619ee8', '#e4f0fc', '#4773a8']; // Base: light

  if (colorMode === 'light') {
    if (transparent && !stuck) {
      fill[1] = '#ffffff';
    }
  } else {
    // fill = ['#6baeff', '#4773a8', '#475778']; // Base: dark
    fill = ['#16549d', '#bdccd4', '#6799cc']; // Base: dark
  }

  if (forceUnicolor !== null) {
    fill = [forceUnicolor, forceUnicolor, forceUnicolor];
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 327.07 54"
      style={{ height }}
    >
      <title>Binate Development</title>
      <g>
        <path
          fill={fill[0]}
          d="M0,46.13V2.49H15.26a16,16,0,0,1,7.52,1.57,10.47,10.47,0,0,1,4.41,4.19,12,12,0,0,1,1.45,5.83,9.74,9.74,0,0,1-1,4.65,8,8,0,0,1-2.6,2.89,12.09,12.09,0,0,1-3.49,1.58v.43a8.5,8.5,0,0,1,4,1.4A10.22,10.22,0,0,1,29,28.7a11.57,11.57,0,0,1,1.36,5.84,11.78,11.78,0,0,1-1.49,5.9,10.32,10.32,0,0,1-4.71,4.15,19.58,19.58,0,0,1-8.37,1.54ZM5.28,21.67h9.8a9.13,9.13,0,0,0,6.06-2.07,6.86,6.86,0,0,0,2.47-5.52,6.66,6.66,0,0,0-2-4.89c-1.33-1.34-3.45-2-6.34-2h-10Zm0,19.77H15.77q5.18,0,7.36-2a6.41,6.41,0,0,0,2.18-4.88,7.8,7.8,0,0,0-1.13-4.11,8.29,8.29,0,0,0-3.21-3A10.2,10.2,0,0,0,16,26.27H5.28Z"
        />
        <path
          fill={fill[0]}
          d="M40.91,7.94a3.55,3.55,0,0,1-2.53-1,3.19,3.19,0,0,1-1.05-2.4,3.2,3.2,0,0,1,1.05-2.41,3.55,3.55,0,0,1,2.53-1,3.51,3.51,0,0,1,2.52,1,3.17,3.17,0,0,1,1.06,2.41,3.17,3.17,0,0,1-1.06,2.4A3.51,3.51,0,0,1,40.91,7.94ZM38.35,46.13V13.4h5V46.13Z"
        />
        <path
          fill={fill[0]}
          d="M57.61,26.44V46.13h-5V13.4h4.86v5.11h.43a9.4,9.4,0,0,1,3.49-4,10.88,10.88,0,0,1,6-1.53,10.9,10.9,0,0,1,8,3q3,3,3,9.31v20.8h-5V25.67a8.5,8.5,0,0,0-2-6A7.13,7.13,0,0,0,66,17.49a8.18,8.18,0,0,0-6,2.33A8.84,8.84,0,0,0,57.61,26.44Z"
        />
        <path
          fill={fill[0]}
          d="M97.33,46.89a13.17,13.17,0,0,1-5.65-1.18,9.27,9.27,0,0,1-5.52-8.87,8.43,8.43,0,0,1,1.11-4.57,7.78,7.78,0,0,1,3-2.76A16.13,16.13,0,0,1,94.33,28c1.5-.33,3-.6,4.53-.79,2-.26,3.61-.46,4.85-.59a7.73,7.73,0,0,0,2.73-.69,1.81,1.81,0,0,0,.86-1.71v-.17a6.68,6.68,0,0,0-1.72-4.9,7,7,0,0,0-5.18-1.75A9.06,9.06,0,0,0,94.75,19a9.37,9.37,0,0,0-2.88,3.37L87.1,20.64A11.39,11.39,0,0,1,90.52,16a12.87,12.87,0,0,1,4.69-2.35,20,20,0,0,1,5-.67,21.44,21.44,0,0,1,3.63.37,11.8,11.8,0,0,1,4,1.53,8.88,8.88,0,0,1,3.21,3.47,12.94,12.94,0,0,1,1.28,6.22V46.13h-5V41.69H107a8.94,8.94,0,0,1-1.7,2.28A9.9,9.9,0,0,1,102.17,46,12.26,12.26,0,0,1,97.33,46.89Zm.77-4.51a9.93,9.93,0,0,0,5-1.18,8.17,8.17,0,0,0,3.12-3,7.82,7.82,0,0,0,1-3.9v-4.6a3,3,0,0,1-1.4.69,20.24,20.24,0,0,1-2.47.53q-1.4.23-2.7.39l-2.12.26a20,20,0,0,0-3.66.82,6.29,6.29,0,0,0-2.72,1.69,4.27,4.27,0,0,0-1,3,4.46,4.46,0,0,0,2,3.95A8.59,8.59,0,0,0,98.1,42.38Z"
        />
        <path
          fill={fill[0]}
          d="M135.77,13.4v4.26h-7V36.75a6.39,6.39,0,0,0,.63,3.19,3,3,0,0,0,1.61,1.4,6.17,6.17,0,0,0,2.1.35,8.53,8.53,0,0,0,1.37-.09l.85-.16,1,4.52a13.6,13.6,0,0,1-1.42.39,11.54,11.54,0,0,1-2.33.2,10,10,0,0,1-4.16-.91,8.22,8.22,0,0,1-3.37-2.8,7.94,7.94,0,0,1-1.33-4.73V17.66h-4.94V13.4h4.94V5.56h5V13.4Z"
        />
        <path
          fill={fill[0]}
          d="M156.73,46.81a15.28,15.28,0,0,1-8.15-2.1,13.85,13.85,0,0,1-5.26-5.88A19.87,19.87,0,0,1,141.48,30a20.34,20.34,0,0,1,1.84-8.88,14.53,14.53,0,0,1,5.16-6A13.83,13.83,0,0,1,156.22,13a15.75,15.75,0,0,1,5.05.85,12.7,12.7,0,0,1,4.54,2.76,13.54,13.54,0,0,1,3.26,5,21.46,21.46,0,0,1,1.21,7.72v2.13H146.53q.16,5.24,3,8a9.81,9.81,0,0,0,7.23,2.79A9.71,9.71,0,0,0,161.82,41a7.29,7.29,0,0,0,3.09-3.83l4.86,1.36a10.94,10.94,0,0,1-4.6,6A15,15,0,0,1,156.73,46.81Zm-10.2-19.69h18.64a10,10,0,0,0-2.43-6.89,8.26,8.26,0,0,0-6.52-2.74,9.15,9.15,0,0,0-5,1.34,9.75,9.75,0,0,0-3.32,3.55A11.21,11.21,0,0,0,146.53,27.12Z"
        />
        <path
          fill={fill[1]}
          d="M176.88,54.54V0h10.91V3.24h-7.28V51.31h7.28v3.23Z"
        />
        <path
          fill={fill[1]}
          d="M198.53,46.19a3,3,0,0,1-2.17-.91,3.05,3.05,0,0,1,0-4.33,3,3,0,0,1,2.17-.9,3.07,3.07,0,0,1,0,6.14Z"
        />
        <path
          fill={fill[1]}
          d="M211.76,46.19a3,3,0,0,1-2.17-.91,3,3,0,1,1,2.17.91Z"
        />
        <path
          fill={fill[2]}
          d="M225,46.19a3.09,3.09,0,1,1,2.17-.91A3,3,0,0,1,225,46.19Z"
        />
        <path
          fill={fill[2]}
          d="M245,46.46a9.58,9.58,0,0,1-5.63-1.7A11.16,11.16,0,0,1,235.61,40a17.53,17.53,0,0,1-1.35-7.12,17.17,17.17,0,0,1,1.36-7.08,11.21,11.21,0,0,1,3.8-4.74,9.68,9.68,0,0,1,5.66-1.7,8.41,8.41,0,0,1,4,.85,8,8,0,0,1,2.54,2.09,11.56,11.56,0,0,1,1.47,2.41h.28V11h3V45.91h-3V41.05h-.36a11.47,11.47,0,0,1-1.5,2.45A8,8,0,0,1,249,45.61,8.42,8.42,0,0,1,245,46.46Zm.36-2.78a6.85,6.85,0,0,0,4.33-1.39,8.73,8.73,0,0,0,2.73-3.84,15.56,15.56,0,0,0,1-5.61,15.16,15.16,0,0,0-.94-5.55,8.39,8.39,0,0,0-2.73-3.78,7.55,7.55,0,0,0-8.77,0,8.79,8.79,0,0,0-2.72,3.84,15.07,15.07,0,0,0-.93,5.44,15.4,15.4,0,0,0,.93,5.5,8.88,8.88,0,0,0,2.73,3.9A6.88,6.88,0,0,0,245.39,43.68Z"
        />
        <path
          fill={fill[2]}
          d="M275.63,46.46a11.55,11.55,0,0,1-6.35-1.71A11.36,11.36,0,0,1,265.15,40a16.1,16.1,0,0,1-1.46-7,16.21,16.21,0,0,1,1.46-7,11.77,11.77,0,0,1,4-4.82,10.44,10.44,0,0,1,6-1.75,11,11,0,0,1,5.37,1.39A10.53,10.53,0,0,1,284.66,25a14.67,14.67,0,0,1,1.58,7.22v1.5h-19.5A11,11,0,0,0,269.23,41a8.08,8.08,0,0,0,6.4,2.71,7.61,7.61,0,0,0,4.56-1.27,7.2,7.2,0,0,0,2.53-3l2.88.94a9.08,9.08,0,0,1-3.46,4.3A10.91,10.91,0,0,1,275.63,46.46Zm-8.87-15.41h16.38a10,10,0,0,0-1-4.54,8,8,0,0,0-2.81-3.2,7.93,7.93,0,0,0-8.37,0A8.9,8.9,0,0,0,268,26.58,11.17,11.17,0,0,0,266.76,31.05Z"
        />
        <path
          fill={fill[2]}
          d="M313.18,19.73l-9.57,26.18h-3.24l-9.56-26.18h3.29l7.77,22.18h.24l7.78-22.18Z"
        />
        <path
          fill={fill[1]}
          d="M327.07.23V54.78H316.16V51.54h7.28V3.47h-7.28V.23Z"
        />
      </g>
    </svg>
  );
};

export default Brand;
