import React from 'react';
import {
  Box,
  Container,
  Grid,
  VStack,
  Stack,
  Text,
  Flex,
  Icon,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import Brand from '../../ui/Brand';
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons';
import { DiReact, DiFirebase } from 'react-icons/di';

const Footer = () => {
  const c = {
    base: useColorModeValue('gray.500', 'brand.500'),
    accent: useColorModeValue('brand.300', 'brand.200'),
    bg: useColorModeValue('palette.b6', 'brand.900'),
  }; // Text colors

  return (
    <Box
      as={'section'}
      w={'100%'}
      bg={c.bg}
      overflow="hidden"
      px={4}
      boxSizing="border-box"
    >
      <Container maxW="1200px" py={8} color={c.base}>
        <Flex align={'center'} justify={'center'}>
          <VStack spacing={6}>
            <Box minW={'120px'} maxW={'200px'} minH={'30px'}>
              <Brand forceUnicolor={'#ffffff'} />
            </Box>
            <Grid
              templateColumns={{
                sm: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <VStack w={'100%'} align={'stretch'}>
                <Text fontWeight={'bold'}>Binate Development</Text>
                <Box fontSize={'sm'}>
                  Nieuwlandseweg 25
                  <br /> 215 AV Hilversum
                  <br />
                  KvK 81229755
                  <br /> Vat NL003543338B42
                  <br />
                  <Box display={'inline-flex'} alignItems={'center'}>
                    <EmailIcon w={3} h={3} mr={1} />
                    <Link href={'mailto:binate.development@outlook.com'}>
                      binate.development@outlook.com
                    </Link>
                  </Box>{' '}
                  <br />
                  <Box display={'inline-flex'} alignItems={'center'}>
                    <PhoneIcon w={3} h={3} mr={1} />
                    +31 6 1795 7571
                  </Box>
                </Box>
              </VStack>
              <Box>
                <Text
                  textAlign={{ base: 'left', md: 'right' }}
                  fontSize={'sm'}
                  fontWeight={'500'}
                  mt={[3, 0]}
                >
                  Website powered by:
                </Text>
                <Stack
                  direction={{ base: 'row', md: 'row-reverse' }}
                  mt={3}
                  spacing={2}
                >
                  <VStack maxW={'50%'} spacing={0}>
                    <Icon as={DiFirebase} w={'25px'} h={'25px'} />
                    <Box fontSize={'xs'}>Firebase</Box>
                  </VStack>
                  <VStack maxW={'50%'} spacing={0}>
                    <Icon as={DiReact} w={'25px'} h={'25px'} />
                    <Box fontSize={'xs'}>React JS</Box>
                  </VStack>
                </Stack>
              </Box>
            </Grid>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
