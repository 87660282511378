import { extendTheme } from '@chakra-ui/react';
import Button from './components/button';
import Header from './components/header';
import Navigation from './components/navigation';
import colors from './foundations/colors';
import styles from './styles';

const overrides = {
  colors,
  components: {
    Button,
    Header,
    Navigation,
  },
  styles,
};

export default extendTheme(overrides);
