import { useColorModeValue, useTheme } from '@chakra-ui/react';
import React from 'react';

export const CloudsSvg = () => {
  const {
    colors: { brand },
  } = useTheme();

  const cloudColor = useColorModeValue('#fff', brand[600]);
  const screenBg = useColorModeValue('#fff', brand[700]);
  const frameBg = useColorModeValue('#e4f0fc', brand[800]);
  const iconsBg = useColorModeValue('#4773a8', brand[300]);
  const outlineColor = useColorModeValue('#4773a8', brand[900]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 665.54 445.98"
      style={{ width: '100%', height: 'auto' }}
    >
      <defs>
        <style>
          {`.cls-1{fill:url(#linear-gradient);}.cls-2{fill:url(#linear-gradient-2);}.cls-3{fill:${screenBg};}.cls-3,.cls-4,.cls-6{stroke:${outlineColor};stroke-miterlimit:10;}.cls-4{fill:${frameBg};}.cls-5{fill:${iconsBg};}.cls-6{fill:none;}`}
        </style>
        <linearGradient
          id="linear-gradient"
          x1="-143.51"
          y1="-150.52"
          x2="457.62"
          y2="450.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={cloudColor} />
          <stop offset="1" stopColor={cloudColor} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="87.69"
          y1="-98.11"
          x2="583.62"
          y2="397.82"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <title>Web and Mobile App development.</title>
      <g>
        <g id="clouds">
          <path
            className="cls-1"
            d="M400.7,446a156.22,156.22,0,0,0,87.52-285.61C463.23,68,378.79,0,278.45,0S93.68,68,68.68,160.37A156.22,156.22,0,0,0,156.21,446Z"
          />
          <path
            className="cls-2"
            d="M536.67,394a128.88,128.88,0,0,0,72.2-235.63,179.33,179.33,0,0,0-346.12,0A128.88,128.88,0,0,0,335,394Z"
          />
        </g>
        <g id="browser">
          <path
            className="cls-3"
            d="M546.22,92.68V369.16a5.39,5.39,0,0,1-5.38,5.38H198.31a5.39,5.39,0,0,1-5.38-5.38V92.68Z"
          />
          <path
            className="cls-4"
            d="M546.22,75.91v16.9H192.94V75.91a5.39,5.39,0,0,1,5.38-5.38H540.84A5.39,5.39,0,0,1,546.22,75.91Z"
          />
          <rect
            className="cls-5"
            x="198.53"
            y="78.86"
            width="6.14"
            height="6.14"
            rx="3.06"
            ry="3.06"
          />
          <rect
            className="cls-5"
            x="206.92"
            y="78.86"
            width="6.14"
            height="6.14"
            rx="3.06"
            ry="3.06"
          />
          <rect
            className="cls-5"
            x="215.31"
            y="78.86"
            width="6.14"
            height="6.14"
            rx="3.06"
            ry="3.06"
          />
        </g>
        <path
          id="g-cloud"
          className="cls-5"
          d="M379,219.72a9.78,9.78,0,0,0-18.88,0h0c-.16.1-.31.22-.45.33h0a7.07,7.07,0,0,0,4.51,12.51H375A7,7,0,0,0,379,219.72Zm-1.21,5.89a2.47,2.47,0,0,1-2.46,2.46H363.84a2.46,2.46,0,0,1-2-1l0,0a2.45,2.45,0,0,1-.47-1.44v-.41a2.47,2.47,0,0,1,2.46-2.46h.46a2.76,2.76,0,0,1,2.37,1.63l1.69-1.64,1.47-1.43a7.05,7.05,0,0,0-4.34-2.75,5.43,5.43,0,0,1,9.62,3.46,5.16,5.16,0,0,1,0,.72h.25a2.47,2.47,0,0,1,2.46,2.46Z"
        />
        <g id="phone">
          <rect
            className="cls-4"
            x="119.32"
            y="133.59"
            width="112.51"
            height="246.79"
            rx="12"
            ry="12"
          />
          <rect
            className="cls-3"
            x="121.13"
            y="137.57"
            width="108.88"
            height="238.83"
            rx="12"
            ry="12"
          />
          <path
            className="cls-4"
            d="M232.73,210.41h-.91V177.75h.91c.5,0,.91.54.91,1.21V209.2C233.64,209.87,233.23,210.41,232.73,210.41Z"
          />
          <path
            className="cls-4"
            d="M232.73,243.07h-.91V225.53h.91c.5,0,.91.29.91.65v16.24C233.64,242.78,233.23,243.07,232.73,243.07Z"
          />
          <circle className="cls-6" cx="175.57" cy="142.66" r="2.42" />
        </g>
        <path
          id="g-cloud-2"
          className="cls-5"
          d="M185.89,254.17a9.78,9.78,0,0,0-18.88,0h0c-.16.1-.31.22-.45.33h0A7.07,7.07,0,0,0,171,267h10.9a7,7,0,0,0,3.94-12.86Zm-1.21,5.89a2.47,2.47,0,0,1-2.46,2.46H170.74a2.46,2.46,0,0,1-2-1l0,0a2.45,2.45,0,0,1-.47-1.44v-.41a2.47,2.47,0,0,1,2.46-2.46h.46a2.76,2.76,0,0,1,2.37,1.63l1.69-1.64,1.47-1.43a7.05,7.05,0,0,0-4.34-2.75,5.43,5.43,0,0,1,9.62,3.46,5.16,5.16,0,0,1,0,.72h.25a2.47,2.47,0,0,1,2.46,2.46Z"
        />
      </g>
    </svg>
  );
};

export default CloudsSvg;
