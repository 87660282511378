import React from 'react';
import { HStack, Button, useStyleConfig } from '@chakra-ui/react';
import { MdMail } from 'react-icons/md';

export const Nav = ({ navRoutes }) => {
  const style = useStyleConfig('Navigation', {});

  return (
    <HStack spacing="5" display={{ base: 'none', md: 'flex' }}>
      {navRoutes.map((route, i) => (
        <Button key={route.path} sx={style} isActive={!i}>
          {route.title}
        </Button>
      ))}
      <Button
        as="a"
        href="mailto:binate.development@outlook.com"
        leftIcon={<MdMail />}
        sx={style}
      >
        Contact
      </Button>
    </HStack>
  );
};

export default Nav;
