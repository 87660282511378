import React from 'react';
import {
  Box,
  Container,
  Stack,
  Image,
  Flex,
  Link,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';

const Companies = () => {
  const c = {
    base: useColorModeValue('gray.500', 'brand.500'),
    accent: useColorModeValue('brand.300', 'brand.200'),
    bg: useColorModeValue('#ffffff', 'brand.900'),
  }; // Text colors

  const hover = {
    borderTop: '1px',
    borderTopColor: 'gray.100',
    borderBottom: '1px',
    borderBottomColor: 'gray.100',
  };

  return (
    <Box
      as={'section'}
      w={'100%'}
      bg={c.bg}
      overflow="hidden"
      px={4}
      boxSizing="border-box"
    >
      <Container
        maxW="1200px"
        py={{ base: 8, lg: '60px' }}
        px={0}
        color={c.base}
      >
        <Heading
          as={'h2'}
          size={'xl'}
          lineHeight={'1.5'}
          color={c.base}
          fontWeight={'thin'}
          textAlign={'center'}
        >
          I Worked With
        </Heading>
        <Flex align={'center'} justify={'center'} mt={8}>
          <Stack direction={["column", "row"]} spacing={12}>
            <Link href={'https://www.unravelresearch.com/'} target={'_blank'}>
              <Flex
                h={'100px'}
                align={'center'}
                justify={'center'}
                _hover={hover}
              >
                <Image
                  src={'/clients/unravel.png'}
                  alt={'Unravel Research'}
                  title={'Unravel Research'}
                  w={'200px'}
                />
              </Flex>
            </Link>
            <Link href={'https://r2s.nu/'} target={'_blank'}>
              <Flex
                h={'100px'}
                align={'center'}
                justify={'center'}
                _hover={hover}
              >
                <Image
                  src={'/clients/r2s.svg'}
                  alt={'Return2Sender'}
                  title={'Return2Sender'}
                  w={'200px'}
                />
              </Flex>
            </Link>
            <Link href={'https://orangeplastics.com/'} target={'_blank'}>
              <Flex
                h={'100px'}
                align={'center'}
                justify={'center'}
                _hover={hover}
              >
                <Image
                  src={'/clients/orange-plastics.svg'}
                  alt={'Orange Plastics'}
                  title={'Orange Plastics'}
                  w={'200px'}
                />
              </Flex>
            </Link>
          </Stack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Companies;
