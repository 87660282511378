import React from 'react';
import {
  useColorModeValue,
  Box,
  Container,
  Grid,
  VStack,
  Flex,
  Heading,
  Text,
  Icon,
} from '@chakra-ui/react';

const Approach = () => {
  const c = {
    base: useColorModeValue('#293241', 'gray.100'),
    accent: useColorModeValue('#323031', 'gray.50'),
    bg: useColorModeValue('#FFFFFF', 'gray.50'),
    icon: useColorModeValue('palette.b4', 'palette.r1'),
  }; // Text colors
  return (
    <Box
      as={'section'}
      w={'100%'}
      bg={c.bg}
      overflow="hidden"
      px={4}
      boxSizing="border-box"
    >
      <Container maxW="1200px" py={{ base: 8, lg: '60px' }} px={0}>
        <Heading
          as={'h2'}
          size={'3xl'}
          textAlign={'center'}
          color={c.base}
          fontWeight={'normal'}
          mb={10}
        >
          My Approach
        </Heading>
        <Grid
          templateColumns={{
            sm: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
          gap={{ base: 6, lg: 8 }}
        >
          <VStack align={'stretch'}>
            <Flex align={'center'} justify={'center'}>
              <Icon viewBox={'0 0 20 20'} w={'50px'} h={'50px'}>
                <path
                  fill="rgb(97, 158, 232)"
                  d="M11 3C11 2.44772 10.5523 2 10 2C9.44771 2 9 2.44772 9 3V4C9 4.55228 9.44771 5 10 5C10.5523 5 11 4.55228 11 4V3Z"
                />
                <path
                  fill="rgb(97, 158, 232)"
                  d="M15.6568 5.75731C16.0473 5.36678 16.0473 4.73362 15.6568 4.34309C15.2663 3.95257 14.6331 3.95257 14.2426 4.34309L13.5355 5.0502C13.145 5.44072 13.145 6.07389 13.5355 6.46441C13.926 6.85494 14.5592 6.85494 14.9497 6.46441L15.6568 5.75731Z"
                />
                <path
                  fill="rgb(97, 158, 232)"
                  d="M18 10C18 10.5523 17.5523 11 17 11H16C15.4477 11 15 10.5523 15 10C15 9.44771 15.4477 9 16 9H17C17.5523 9 18 9.44771 18 10Z"
                />
                <path
                  fill="rgb(97, 158, 232)"
                  d="M5.05019 6.46443C5.44071 6.85496 6.07388 6.85496 6.4644 6.46443C6.85493 6.07391 6.85493 5.44074 6.4644 5.05022L5.7573 4.34311C5.36677 3.95259 4.73361 3.95259 4.34308 4.34311C3.95256 4.73363 3.95256 5.3668 4.34308 5.75732L5.05019 6.46443Z"
                />
                <path
                  fill="rgb(97, 158, 232)"
                  d="M5 10C5 10.5523 4.55228 11 4 11H3C2.44772 11 2 10.5523 2 10C2 9.44771 2.44772 9 3 9H4C4.55228 9 5 9.44771 5 10Z"
                />
                <path
                  fill="#1D3557"
                  d="M8 16V15H12V16C12 17.1046 11.1046 18 10 18C8.89543 18 8 17.1046 8 16Z"
                />
                <path
                  fill="#1D3557"
                  d="M12.0009 14C12.0155 13.6597 12.2076 13.3537 12.4768 13.1411C13.4046 12.4086 14 11.2738 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 11.2738 6.59545 12.4086 7.52319 13.1411C7.79241 13.3537 7.98451 13.6597 7.99911 14H12.0009Z"
                />
              </Icon>
            </Flex>
            <Heading
              as={'h5'}
              size={'lg'}
              py={3}
              textAlign={'center'}
              color={c.base}
            >
              Define
            </Heading>
            <Text textAlign={'center'} fontSize={'sm'} color={c.accent}>
              Brainstorm ideas that solve particular problem faced by you.
              Outline the scope and schedule of the project, by answering the
              most important questions: how and when do we start?
            </Text>
          </VStack>
          <VStack align={'stretch'}>
            <Flex align={'center'} justify={'center'}>
              <Icon viewBox={'0 0 24 24'} w={'50px'} h={'50px'}>
                <path
                  d="M11 4C11 2.89543 11.8954 2 13 2C14.1046 2 15 2.89543 15 4V5C15 5.55228 15.4477 6 16 6H19C19.5523 6 20 6.44772 20 7V10C20 10.5523 19.5523 11 19 11H18C16.8954 11 16 11.8954 16 13C16 14.1046 16.8954 15 18 15H19C19.5523 15 20 15.4477 20 16V19C20 19.5523 19.5523 20 19 20H16C15.4477 20 15 19.5523 15 19V18C15 16.8954 14.1046 16 13 16C11.8954 16 11 16.8954 11 18V19C11 19.5523 10.5523 20 10 20H7C6.44772 20 6 19.5523 6 19V16C6 15.4477 5.55228 15 5 15H4C2.89543 15 2 14.1046 2 13C2 11.8954 2.89543 11 4 11H5C5.55228 11 6 10.5523 6 10V7C6 6.44772 6.44772 6 7 6H10C10.5523 6 11 5.55228 11 5V4Z"
                  fill="rgb(97, 158, 232)"
                  stroke="#1D3557"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </Icon>
            </Flex>
            <Heading
              as={'h5'}
              size={'lg'}
              py={3}
              textAlign={'center'}
              color={c.base}
            >
              Design
            </Heading>
            <Text textAlign={'center'} fontSize={'sm'} color={c.accent}>
              Map out the application, create prototypes and refine the
              requirements to provide a tailor made solution. Design a flexible
              architecture to support future features and changes.
            </Text>
          </VStack>
          <VStack align={'stretch'}>
            <Flex align={'center'} justify={'center'}>
              <Icon viewBox={'0 0 12 12'} w={'50px'} h={'50px'}>
                <line
                  fill="none"
                  stroke="rgb(97, 158, 232)"
                  stroke-linecap="round"
                  stroke-linecap="round"
                  stroke-miterlimit="2"
                  x1="5"
                  y1="10"
                  x2="7"
                  y2="2"
                />
                <polyline
                  fill="none"
                  stroke="#1D3557"
                  stroke-linecap="round"
                  stroke-linecap="round"
                  stroke-miterlimit="2"
                  points="9,4 11,6 9,8 "
                />
                <polyline
                  fill="none"
                  stroke="#1D3557"
                  stroke-linecap="round"
                  stroke-linecap="round"
                  stroke-miterlimit="2"
                  points="3,8 1,6 3,4 "
                />
              </Icon>
            </Flex>
            <Heading
              as={'h5'}
              size={'lg'}
              py={3}
              textAlign={'center'}
              color={c.base}
            >
              Develop
            </Heading>
            <Text textAlign={'center'} fontSize={'sm'} color={c.accent}>
              Use agile development process for the maximum communication and
              progress reporting, in conjunction with Test Driven Development
              (TDD) to ensure high quality code, modularity, easier refactoring
              and adaptation to changes.
            </Text>
          </VStack>
          <VStack align={'stretch'}>
            <Flex align={'center'} justify={'center'}>
              <Icon viewBox={'0 0 12 12'} w={'50px'} h={'50px'}>
                <path
                  fill="rgb(97, 158, 232)"
                  d="M6,9.9c-0.4,0-0.8-0.2-1-0.3c-0.1-0.1-0.3,0-0.3,0.2L4.8,11c0,0.1,0.1,0.1,0.1,0.1l0.3-0.3c0,0,0.1,0,0.1,0l0.5,0.8
	c0,0.1,0.1,0.1,0.2,0l0.5-0.8c0,0,0.1-0.1,0.1,0L7,11.1c0.1,0,0.1,0,0.1-0.1l0.1-1.2c0-0.1-0.1-0.2-0.3-0.2C6.8,9.7,6.4,9.9,6,9.9z"
                />
                <path
                  fill="#1D3557"
                  d="M9.3,6.9L8.2,5.7C8.2,2,6,0.4,6,0.4S3.8,2,3.8,5.7L2.7,6.9c0,0-0.1,0.1-0.1,0.2l0.4,2.6c0,0.1,0.2,0.2,0.3,0.1l1.6-1.1
	c0,0,0.5,0.5,1.1,0.5c0.6,0,1.1-0.5,1.1-0.5l1.6,1.1c0.1,0.1,0.3,0,0.3-0.1L9.4,7C9.4,7,9.4,6.9,9.3,6.9z M6,4.5
	c-0.4,0-0.7-0.3-0.7-0.8S5.6,3,6,3c0.4,0,0.7,0.3,0.7,0.8S6.4,4.5,6,4.5z"
                />
              </Icon>
            </Flex>
            <Heading
              as={'h5'}
              size={'lg'}
              py={3}
              textAlign={'center'}
              color={c.base}
            >
              Deliver
            </Heading>
            <Text textAlign={'center'} fontSize={'sm'} color={c.accent}>
              Support with setting up production environment for a smooth launch
              and provide you with the source code so you can own your
              Intellectual Property (IP).
            </Text>
          </VStack>
        </Grid>
      </Container>
    </Box>
  );
};

export default Approach;
