import React, { useRef } from 'react';
import {
  Button,
  CloseButton,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  useStyleConfig,
  useStyles,
  useUpdateEffect,
  VStack,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { RemoveScroll } from 'react-remove-scroll';
import Brand from '../../../ui/Brand';
import ColorModeSwitcher from '../../../ui/ColorModeSwitcher';
import { MdMail } from 'react-icons/md';

export const MobileNavContent = ({ isOpen, onClose, navRoutes }) => {
  const headStyles = useStyles();
  const closeBtnRef = useRef();

  const navStyle = useStyleConfig('Navigation', {});
  const closeColor = useColorModeValue('brand.800', 'brand.300');

  useUpdateEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => {
        closeBtnRef.current?.focus();
      });
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <RemoveScroll forwardProps>
          <motion.div
            transition={{ duration: 0.08 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Flex
              sx={headStyles.mobileNav}
              direction="column"
              w="100%"
              h="100vh"
              overflow="auto"
              pos="absolute"
              top="0"
              left="0"
              zIndex={20}
              pb="8"
            >
              <Flex
                justify="space-between"
                height="4.5rem"
                align="center"
                px="6"
                mb="4"
              >
                <Brand height="28%" />
                <HStack>
                  <ColorModeSwitcher color="gray.400" />
                  <CloseButton
                    ref={closeBtnRef}
                    onClick={onClose}
                    color={closeColor}
                  />
                </HStack>
              </Flex>
              <VStack flex="1" px="4" spacing="0">
                {navRoutes.map(route =>
                  route.routes ? (
                    <React.Fragment key={route.path}>
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        w="100%"
                        pt="6"
                        pb="2"
                        pl="4"
                        textColor="gray.700"
                      >
                        {route.title.toUpperCase()}
                      </Text>
                      {route.routes.map(subRoute => (
                        <Button
                          sx={navStyle}
                          href={subRoute.path}
                          key={subRoute.path}
                          justifyContent="flex-start"
                          isFullWidth
                        >
                          {subRoute.title}
                        </Button>
                      ))}
                    </React.Fragment>
                  ) : (
                    <Button
                      sx={navStyle}
                      href={route.path}
                      key={route.path}
                      justifyContent="flex-start"
                      isFullWidth
                    >
                      {route.title}
                    </Button>
                  )
                )}
                <Button
                  as="a"
                  href="mailto:binate.development@outlook.com"
                  leftIcon={<MdMail />}
                  sx={navStyle}
                  justifyContent="flex-start"
                  isFullWidth
                >
                  Contact
                </Button>
              </VStack>
            </Flex>
          </motion.div>
        </RemoveScroll>
      )}
    </AnimatePresence>
  );
};

export default MobileNavContent;
