import { mode } from '@chakra-ui/theme-tools';

const transitionSpeed = '0.5s';

const Header = {
  parts: ['container', 'contentWrap', 'brand', 'mobileNav'],
  baseStyle: props => ({
    container: {
      bg: mode('white', 'brand.900')(props),
      transition: `background ${transitionSpeed}`,
    },
    contentWrap: {
      transition: `all ${transitionSpeed}`,
    },
    mobileNav: {
      bg: mode('white', 'brand.900')(props),
    },
  }),
  sizes: {
    sm: {
      contentWrap: {
        height: '3.5rem',
      },
    },
    md: {
      contentWrap: {
        height: '4.5rem',
      },
    },
  },
  variants: {
    transparent: {
      container: {
        bg: 'transparent',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export default Header;
