import React from 'react';
import {
  useColorModeValue,
  Box,
  Container,
  Grid,
  VStack,
  Heading,
  Text,
  Flex,
} from '@chakra-ui/react';

const Technology = () => {
  const c = {
    base: useColorModeValue('gray.800', 'gray.100'),
    accent: useColorModeValue('gray.700', 'gray.50'),
  }; // Text colors

  const hover = {
    textDecoration: 'underline',
    textDecorationColor: '#c5c3c7',
  };

  return (
    <Box
      as={'section'}
      w={'100%'}
      bg={useColorModeValue('gray.50', 'brand.700')}
      overflow="hidden"
      px={4}
      boxSizing="border-box"
    >
      <Container maxW="1200px" py={{ base: 8, lg: '60px' }} px={0}>
        <Flex
          align={'center'}
          justify={'center'}
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Heading
            as={'h2'}
            size={'2xl'}
            lineHeight={'1.5'}
            color={c.base}
            fontWeight={'thin'}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            Ahead with the newest technologies
          </Heading>
          <Grid
            templateColumns={{
              sm: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(3, 1fr)',
            }}
            gap={{ base: 6, lg: 8 }}
            w={'80%'}
            mt={{ base: 5, lg: 0 }}
          >
            <VStack align={'stretch'} role="group">
              <Heading
                as={'h5'}
                size={'md'}
                pb={3}
                color={c.base}
                fontWeight={500}
                _groupHover={hover}
              >
                Custom Build
              </Heading>
              <Text fontSize={'sm'} color={c.accent}>
                From design to documentation. I build it exactly the product you
                want.
              </Text>
            </VStack>
            <VStack align={'stretch'} role="group">
              <Heading
                as={'h5'}
                size={'md'}
                pb={3}
                color={c.base}
                fontWeight={500}
                _groupHover={hover}
              >
                Responsive
              </Heading>
              <Text fontSize={'sm'} color={c.accent}>
                A single application ready for desktop and mobile screens.
              </Text>
            </VStack>
            <VStack align={'stretch'} role="group">
              <Heading
                as={'h5'}
                size={'md'}
                pb={3}
                color={c.base}
                fontWeight={500}
                _groupHover={hover}
              >
                Single Page
              </Heading>
              <Text fontSize={'sm'} color={c.accent}>
                Fast and responsive. Update only required content without a page
                reload and receive real-time information.
              </Text>
            </VStack>
            <VStack align={'stretch'} role="group">
              <Heading
                as={'h5'}
                size={'md'}
                pb={3}
                color={c.base}
                fontWeight={500}
                _groupHover={hover}
              >
                Progressive Web App
              </Heading>
              <Text fontSize={'sm'} color={c.accent}>
                Use the web application as a mobile app without the need for
                significant investment.
              </Text>
            </VStack>
            <VStack align={'stretch'} role="group">
              <Heading
                as={'h5'}
                size={'md'}
                pb={3}
                color={c.base}
                fontWeight={500}
                _groupHover={hover}
              >
                Scalable
              </Heading>
              <Text fontSize={'sm'} color={c.accent}>
                Cloud technologies allow you to go from several users to
                thousands of users without any problems.
              </Text>
            </VStack>
            <VStack align={'stretch'} role="group">
              <Heading
                as={'h5'}
                size={'md'}
                pb={3}
                color={c.base}
                fontWeight={500}
                _groupHover={hover}
              >
                Real-time
              </Heading>
              <Text fontSize={'sm'} color={c.accent}>
                Cloud storage gives you an option to receive real-time changes
                to data without the need to refresh a page.
              </Text>
            </VStack>
          </Grid>
        </Flex>
      </Container>
    </Box>
  );
};

export default Technology;
