const colors = {
  brand: {
    50: '#e3f2ff',
    100: '#b9d6f9',
    200: '#8fbbf0',
    300: '#639fe8', // base #619EE8
    400: '#3a84e1',
    500: '#216bc7',
    600: '#17539c',
    700: '#0d3b70',
    800: '#032446',
    900: '#000d1c',
  },
  gray: {
    50: '#f9f9fa',
    100: '#eeedee',
    200: '#e1e0e2',
    300: '#d4d2d5',
    400: '#c5c3c7',
    500: '#b4b2b7',
    600: '#a19fa5',
    700: '#8b8890',
    800: '#6f6a74',
    900: '#433d4a',
  },
  palette: {
    r1: '#ee6c4d',
    r2: '#E63946',
    b1: '#F1FAEE',
    b2: '#caf0f8',
    b3: '#457B9D',
    b4: '#1D3557',
    b5: '#98c1d9',
    b6: '#343a40',
  },
  icon: {
    1: '#5F5B6B',
    2: '#3D3B3C',
    3: '#323031',
    4: '#5EFC8D',
  },
};

export default colors;
