import React from 'react';
import siteNav from '../configs/site-nav.json';
import Header from '../components/sections/Header';
import Hero from '../components/sections/Hero';
import Approach from '../components/sections/Approach';
import Technology from '../components/sections/Technology';
import What from '../components/sections/What';
import Companies from '../components/sections/Companies';
import Footer from '../components/sections/Footer';

export const App = () => (
  <>
    <Header navRoutes={siteNav.routes} transparent />
    <Hero />
    <What />
    <Approach />
    <Technology />
    <Companies />
    <Footer />
  </>
);

export default App;
