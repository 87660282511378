import { mode } from '@chakra-ui/theme-tools';

const baseStyle = props => {
  const { colorScheme: c } = props;

  return {
    bg: 'transparent',
    color: mode(`${c}.700`, `${c}.200`)(props),
    _active: {
      bg: `${c}.300`,
      color: 'white',
      _hover: {
        bg: `${c}.400`,
      },
    },
    _hover: {
      bg: 'transparent',
      color: `${c}.400`,
    },
  };
};

const defaultProps = {
  variant: 'solid',
  size: 'md',
  colorScheme: 'brand',
};

const Navigation = {
  baseStyle,
  defaultProps,
};

export default Navigation;
